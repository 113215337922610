<template>
  <div class="side-nav" :class="{ 'side-nav-open': open }">
    <button class="toggle" @click="$emit('toggleModal')">
      <span class="utopia-blk-headline toggle-title">Show <span>me more</span></span>
      <div
        v-html="require(`!!html-loader!@/assets/svg/chevron.svg`)"
        class="chevron"
        v-show="!open"
      />
      <div
        v-html="require(`!!html-loader!@/assets/svg/nav-close.svg`)"
        class="close"
        v-show="open"
      />
    </button>
    <transition name="slide-fade">
      <div class="side-nav__inner" v-show="open">
        <div class="side-nav__case-studies">
          <div class="side-nav__main">
            <div class="side-nav__item">
              <!-- Home -->
              <router-link :to="{ name: 'home' }" class="utopia-blk-headline nav-link">
                <span class="nav-link__text">Home</span>
              </router-link>
            </div>
            <div class="side-nav__item">
              <!-- About -->
              <router-link :to="{ name: 'about' }" class="utopia-blk-headline nav-link">
                <h2 class="nav-link__text">Working with Tui</h2>
              </router-link>
            </div>
            <div class="side-nav__item">
              <!-- Demos -->
              <router-link :to="{ name: 'demos' }" class="utopia-blk-headline nav-link">
                <h2 class="nav-link__text">AI in People Development</h2>
              </router-link>
            </div>
          </div>
          <!-- Case studies -->
          <div class="side-nav__case-studies-container">
            <h2 class="utopia-blk-headline side-nav__case-studies-title">Case studies</h2>
            <router-link
              class="case-study"
              :class="[name]"
              v-for="{ title, name, image, route } in links"
              :key="name"
              :to="route"
            >
              <div
                class="case-study__image-container"
                :style="'background-image: url(' + image + ');'"
              />
              <h2 class="case-study__title utopia-blk-headline">{{ title }}</h2>
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// To get real URLs for the thumbnail images
import thumbEY from "@/assets/images/EY__nav-bg.png";
import thumbUseful from "@/assets/images/useful_hero_sm.png";
import thumbGSK from "@/assets/images/GSK_bg_sm.png";

export default {
  name: "NavigationSidebar",
  props: ["open"],
  data() {
    return {
      links: [
        {
          title: "Making connections: Bringing complex data and insight to life for EY", // title for the navigation card
          name: "ey", // name for trackin and keys
          route: {
            name: "ey-article", // route name as in router
          },
          image: thumbEY,
          imageAlt: "EY website screenshot showing a navigation page",
        },
        {
          title: "Make it Useful: Lessons in corporate Learning & Development", // title for the navigation card
          name: "useful", // name for trackin and keys
          image: thumbUseful,
          route: {
            name: "useful-article", // route name as in router
          },
          imageAlt: "Legal And General Useful site screenshot",
        },
        {
          title: "GSK: Digital onboarding for new leaders", // title for the navigation card
          name: "gsk", // name for trackin and keys
          image: thumbGSK,
          route: {
            name: "gsk-article", // route name as in router
          },
          imageAlt: "Image of a GSK worker",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("toggleModal");
      this.$el.blur();
    },
    navigate({ name, params }) {
      this.close();
      if (name !== this.$route.name) {
        this.$router.push({ name, params });
      }
    },
  },
  watch: {
    open(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.$el.tabIndex = 1;
        this.$nextTick(function() {
          this.$el.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width-sm: 352px;
$sidebar-width-md: 369px;
$sidebar-width-lg: 425px;
$sidebar-width-xl: 608px;

/* for IE11 translations, and css minifier */
$side-bar-toggle-pos-vsm: 120px; // $sidebar-width-sm - 16px;
$side-bar-toggle-pos-sm: 66px; // $sidebar-width-sm - 24px;
.side-nav {
  position: fixed;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 3;

  // small
  @media screen and (max-width: #{ $medium - 1 }) {
    align-items: flex-end;
    justify-content: flex-end;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
    align-items: flex-start;
  }

  &__inner {
    align-self: stretch;
    display: flex;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background-color: #35223e;

    .page-ey-article &,
    .page-gsk-article & {
      background-color: #000;
    }

    .page-useful-article & {
      background: #002743;
    }
  }

  &__item {
    display: block;
    & + & {
      margin-top: 40px;
    }
  }

  &.side-nav-open {
    .toggle {
      margin-right: 0;
      // background: #24102d;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      transition: transform 300ms;

      &:hover,
      &:focus {
        background: #000;
      }

      .page-ey-article & {
        background: #24102d;
      }

      .page-useful-article & {
        background: #004e86;
        color: #fff;
      }

      /* Updated these translation calculations to allow for IE11 */
      @media screen and (max-width: 351px) {
        // transform: translateX(calc(- #{$sidebar-width-sm} + 100% + 16px));
        transform: translateX(-$side-bar-toggle-pos-vsm) translateX(100%);
      }
      // small
      @media screen and (min-width: 351px) and (max-width: #{ $medium - 1 }) {
        // transform: translateX(calc(- #{$sidebar-width-sm} + 50% + 24px));
        transform: translateX(-$side-bar-toggle-pos-sm) translateX(50%);
      }
      // medium
      @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
        // transform: translateX(calc(- #{$sidebar-width-md} + 50%));
        transform: translateX(-66px) translateX(50%);
      }
      // large
      @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
        //transform: translateX(calc(-#{$sidebar-width-lg})) translateX(50%);
        transform: translateX(-$sidebar-width-lg) translateX(50%);
      }
      // extra-large
      @media screen and (min-width: $extra-large) {
        // transform: translateX(calc(- #{$sidebar-width-xl} + 50%));
        transform: translateX(-$sidebar-width-xl) translateX(50%);
      }
    }
  }

  .toggle {
    margin-right: 16px; // 32px;
    position: absolute;
    transition: transform 0.3s;
    right: 0;
    z-index: 2;
    width: 98px;
    height: 98px;
    background: #000;
    border-radius: 50%;
    color: $tui-yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 14px;
    transition: background 0.3s;

    &:hover,
    &:focus {
      background: #35223e;
    }

    .page-ey-article & {
      background: #000;

      &:hover,
      &:focus {
        background: #35223e;
      }
    }

    .page-useful-article & {
      background: #004e86;
      color: #fff;
      &:hover,
      &:focus {
        background: #002643;
      }
    }

    .page-gsk-article & {
      background: $gsk-orange;
      color: #fff;
      &:hover,
      &:focus {
        background: #000;
      }
    }

    // small
    @media screen and (max-width: #{ $medium - 1 }) {
      // bottom: 80px;
      top: 24px;
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
      margin-top: 32px;
    }
    // large
    @media screen and (min-width: $extra-large) {
      width: 114px;
      height: 114px;
    }

    &-title {
      // width: 75px; /* IE11 kludge */

      display: inline-flex;
      width: 100%;
      margin-bottom: 8px;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
      line-height: 1;

      span {
        white-space: nowrap;
      }

      // small
      // @media screen and (max-width: #{ $medium - 1 }) {
      //   font-size: 16px;
      //   margin-bottom: 8px;
      // }
      // // medium
      // @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
      //   font-size: 16px;
      //   margin-bottom: 8px;
      // }
      // // large
      // @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
      //   font-size: 16px;
      //   margin-bottom: 8px;
      // }
      // extra-large
      @media screen and (min-width: $extra-large) {
        font-size: 20px;
      }
    }
  }

  &__case-studies {
    background: #35223e;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 32px;
    color: #fff;
    justify-content: flex-start;
    position: relative;

    .page-ey-article & {
      background: #000;
    }
    .page-useful-article & {
      background: #002743;
    }
    .page-gsk-article & {
      background: #000;
    }

    @media screen and (max-width: 351px) {
      max-width: 100%;
      padding-left: 16px;
      // justify-content: flex-start;
      padding-top: 52px;
    }
    // small
    @media screen and (min-width: 352px) and (max-width: #{ $medium - 1 }) {
      padding-left: 16px;
      max-width: $sidebar-width-sm;
      justify-content: flex-start;
      padding-top: 52px;
    }
    // medium
    @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
      width: $sidebar-width-md;
      justify-content: flex-start;
      padding-left: 24px;
      padding-top: 64px;
    }
    // large
    @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
      width: $sidebar-width-lg;
      padding-top: 76px;
      padding-left: 76px; // 80px;
    }
    // extra-large
    @media screen and (min-width: $extra-large) {
      width: $sidebar-width-xl;
      padding-top: 76px;
      padding-left: 76px; // 60px;
    }

    &-title {
      margin-bottom: 20px;

      @media screen and (max-width: #{ $medium - 1 }) {
        font-size: 16px;
        line-height: 18px;
      }

      // medium
      @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
        font-size: 16px;
        line-height: 21px;
      }

      // large
      @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
        font-size: 16px;
        line-height: 21px;
      }

      // extra-large
      @media screen and (min-width: $extra-large) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      margin-top: 72px;
      padding-bottom: 40px;

      /* IE kludges */
      max-width: 100%;
      box-sizing: border-box;

      // small
      @media screen and (max-width: #{ $medium - 1 }) {
        margin-top: 32px;
        max-width: $sidebar-width-sm - 32px;
      }
      // medium
      @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
        margin-top: 64px;
      }
    }
  }
}

.case-study {
  border-top: 1px solid #59406b;
  border-bottom: 1px solid #59406b;
  padding: 32px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s;
  margin-bottom: -1px;
  max-width: 100%;

  .page-ey-article & {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
  }

  .page-useful-article & {
    border-top: 1px solid #648399;
    border-bottom: 1px solid #648399;
  }

  &__image-container {
    border: 2px solid #fff;
    border-radius: 50%;
    background-image: url("~@/assets/images/EY__nav-bg.png");
    background-size: cover;
    flex-shrink: 0;
    transition: all 0.2s;

    // small
    @media screen and (max-width: #{ $medium - 1 }) {
      width: 68px;
      height: 68px;
      margin-right: 24px;
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
      width: 68px;
      height: 68px;
      margin-right: 24px;
    }

    // large
    @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
      width: 92px;
      height: 92px;
      margin-right: 24px;
    }

    // extra-large
    @media screen and (min-width: $extra-large) {
      width: 112px;
      height: 112px;
      margin-right: 14px;
    }
  }

  &.ey {
    .case-study__image-container {
      background-position: 32% 30%;
    }

    &:hover,
    &:focus,
    &.router-link-exact-active {
      border-top: 1px solid #76d5ff;
      border-bottom: 1px solid #76d5ff;

      .case-study__image-container {
        border: 2px solid #76d5ff;
      }

      .case-study__title {
        color: #76d5ff;
      }
    }
  }

  &.useful {
    &:hover,
    &:focus,
    &.router-link-exact-active {
      border-top: 1px solid #f1170f;
      border-bottom: 1px solid #f1170f;

      .case-study__image-container {
        border: 2px solid #f1170f;
      }

      .case-study__title {
        color: #f1170f;
      }
    }
  }

  &.gsk {
    .case-study__image-container {
      background-position: 72% 30%;
    }

    &:hover,
    &:focus,
    &.router-link-exact-active {
      border-top: 1px solid $gsk-orange;
      border-bottom: 1px solid $gsk-orange;

      .case-study__image-container {
        border: 2px solid $gsk-orange;
      }

      .case-study__title {
        color: $gsk-orange;
      }
    }
  }

  &__title {
    color: #fff;
    transition: all 0.2s;

    @media screen and (max-width: #{ $medium - 1 }) {
      font-size: 16px;
      line-height: 18px;
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
      font-size: 16px;
      line-height: 21px;
    }

    // large
    @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
      font-size: 16px;
      line-height: 21px;
    }

    // extra-large
    @media screen and (min-width: $extra-large) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.chevron {
  width: 12px;
  height: 18px;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.close {
  width: 18px;
  height: 18px;
}

.nav-link {
  color: #fff;
  text-decoration: none;

  // medium
  // @media screen and (min-width: $medium) and (max-width: $large - 1) {
  //   margin-left: 48px;
  // }

  // large, extra-large
  // @media screen and (min-width: $large - 1) {
  //   position: absolute;
  //   top: 60px;
  // }

  &__text {
    transition: all 0.2s;

    &:hover,
    &:focus {
      color: $tui-yellow;
    }

    @media screen and (max-width: #{ $medium - 1 }) {
      font-size: 16px;
      line-height: 18px;
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: #{ $large - 1 }) {
      font-size: 16px;
      line-height: 21px;
    }

    // large
    @media screen and (min-width: $large) and (max-width: #{ $extra-large - 1 }) {
      font-size: 16px;
      line-height: 21px;
    }

    // extra-large
    @media screen and (min-width: $extra-large) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.router-link-exact-active {
    [class*="__text"] {
      color: $tui-yellow;
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
