import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: 'Home' */ "../views/Home.vue"),
  },
  {
    path: "/working-with-tui",
    name: "about",
    alias: "/about",
    meta: {
      title: "Working with Tui",
      description:
        "Tui Media is a digital design agency. We make bespoke digital products and services, using cutting edge technology and user centred design.",
    },
    component: () => import(/* webpackChunkName: 'About' */ "../views/About.vue"),
  },
  {
    path: "/ai-in-people-development",
    name: "demos",
    alias: "/ai-demos",
    meta: {
      title: "Using AI to shape the future of People Development",
      description:
        "What is the UI of AI? We’ve tried to tackle this by creating a series of prototypes showcasing AI’s power and potential in People Development.",
    },
    component: () => import(/* webpackChunkName: 'AIDemos' */ "../views/AIDemos.vue"),
  },
  {
    path: "/ey",
    name: "ey-article",
    alias: "/ey-future-consumer-now",
    meta: {
      title: "Making connections: Bringing complex data and insight to life for EY",
      description:
        "A lean-back touch interface for visualising the drivers, personas and new worlds of the future consumer. Tui designed and built this content managed knowledge visualisation tool for Global business advisory firm EY.",
    },
    component: () => import(/* webpackChunkName: 'EYArticle' */ "../views/EYArticle.vue"),
  },
  {
    path: "/useful",
    name: "useful-article",
    alias: "/l-and-g-useful",
    meta: {
      title: "Make it Useful: Lessons in corporate L&D",
      description:
        "We worked with financial services company Legal and General to make a tool that's focused solely on serving people in their moment of need.",
    },
    component: () => import(/* webpackChunkName: 'UsefulArticle' */ "../views/UsefulArticle.vue"),
  },
  {
    path: "/gsk",
    name: "gsk-article",
    meta: {
      title: "GSK: Digital onboarding for new leaders",
      description:
        "We took a typically user-centred approach to building a digital onboarding service for new joiners with leadership experience.",
    },
    component: () => import(/* webpackChunkName: 'UsefulArticle' */ "../views/GSKArticle.vue"),
  },
  {
    path: "/*",
    redirect: {
      name: "home",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({
    x: 0,
    y: 0,
  }),
  routes,
});

export default router;
