<template>
  <div
    id="app"
    class="app"
    :class="[[`page-${$route.name}`], { 'dark-page': darkPage, 'modal-open': modalOpen }]"
  >
    <navigation-sidebar @toggleModal="toggleModal" :open="modalOpen" />

    <app-navigation @toggleModal="toggleModal" :modal-open="modalOpen" />

    <transition appear mode="out-in" name="fade">
      <router-view />
    </transition>

    <app-footer />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter";
import AppNavigation from "@/components/AppNavigation";
import NavigationSidebar from "@/components/NavigationSidebar.vue";

export default {
  name: "App",
  // metaInfo() {
  //   return {
  //     title: this.siteTitle,
  //     link: [
  //       { rel: 'favicon', href: './favicon.ico' },
  //     ],
  //     meta: [
  //       { charset: 'utf-8' },
  //       { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //       { 'http-equiv': "X-UA-Compatible", content: "IE=edge" },
  //       {
  //         property: 'og:url',
  //         content: 'https://tuimedia.com',
  //         vmid: 'ogUrl',
  //       },
  //       {
  //         property: 'og:type',
  //         content: 'website',
  //         vmid: 'ogType',
  //       },
  //       {
  //         property: 'og:image',
  //         content: 'https://uat.tuimedia.com' + require('@/assets/images/meta_thumbnail.png'),
  //         vmid: 'ogImage',
  //       },
  //       {
  //         property: 'og:title',
  //         content: this.siteTitle,
  //         vmid: 'ogTitle',
  //       },
  //       {
  //         vmid: 'ogDescription',
  //         property: 'og:description',
  //         content: 'We’re a small, well-established, fiercely user-centred interactive design agency based in London’s Soho. We provide strategy, UX design, research and full end-to-end builds of digital tools.',
  //       },
  //       {
  //         vmid: 'twitterCard',
  //         name: 'twitter:card',
  //         content: 'We’re a small, well-established, fiercely user-centred interactive design agency based in London’s Soho. We provide strategy, UX design, research and full end-to-end builds of digital tools.',
  //       },
  //       {
  //         vmid: 'twitterSite',
  //         name: 'twitter:site',
  //         content: '@tui_uk',
  //       },
  //       {
  //         vmid: 'themeColor',
  //         name: 'theme-color',
  //         content: '#fce600',
  //       },
  //     ],
  //   };
  // },
  components: {
    AppFooter,
    AppNavigation,
    NavigationSidebar,
  },
  data() {
    return {
      modalOpen: false,
      siteTitle: "Tui Media: Independent digital design agency, London",
    };
  },
  computed: {
    darkPage() {
      return this.$route.name == "ey-article";
    },
  },
  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
  watch: {
    "$route.name"() {
      if (this.modalOpen) {
        this.toggleModal();
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/main.scss";

body {
  margin: 0;
}

.app {
  min-height: 100%;
  font-family: "SlateStd-Regular";
  position: relative;
  overflow-x: hidden;

  transition: background 0.25s ease-in-out;

  &.page-home,
  &.page-about,
  &.page-demos {
    background: $tui-yellow;
  }
  &.page-ey-article {
    background: $ey-bg;
  }
}

p {
  margin: 16px 0;
  margin-top: 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
